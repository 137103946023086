import boxImg from "../assets/open-box.svg";

function ProductHeader(props) {
  console.log(props);

  const piece = props.res.units[0];
  const carton = props.res.units[1];

  let isCarton = false;
  if (props.ean === carton.gtin) {
    isCarton = true;
  }

  const cartonLogo = isCarton ? (
    <div className="product-card__head--box">
      <img src={boxImg} alt="boxImg" />
    </div>
  ) : (
    ""
  );

  return (
    <div className="product-card__head px-8">
      {cartonLogo}
      <div className="product-card__head--picture">
        <img
          src={props.res.photos[0].url}
          className="object-contain w-full mt-4"
          alt="product"
        />
      </div>
      <div className="product-card__head--title mt-4 mb-2">
        {props.res.name.text}
      </div>
      <div className="product-card__head--ean mb-6">
        Symbol: {props.res.code}
      </div>
    </div>
  );
}
export default ProductHeader;
