function ProductDetails(props) {
  const piece = props.res.units[0];
  const carton = props.res.units[1];

  let isCarton = false;
  if (props.ean === carton.gtin) {
    isCarton = true;
  }

  const remarks = props.res.remarks.map((remark, index) => (
    <li key={index} className="remarks">
      {remark.text}
    </li>
  ));

  if (isCarton) {
    return (
      <div className="product-card__details px-8 py-8">
        <ul className="product-card__details--list">
          <li>
            <span className="font-medium">Ean kartonu: </span>
            {carton.gtin}
          </li>

          <li>
            <span className="font-medium">Kraj pochodzenia: </span>
            {props.res.countryOfOrigin}
          </li>

          <li>
            <span className="font-medium">
              Wymiary kartonu (wysokość/ szerokość/ długość):{" "}
            </span>
            {carton.dimensions.height} x {carton.dimensions.width} x{" "}
            {carton.dimensions.length}
          </li>

          <li>
            <span className="font-medium">Waga kartonu: </span>
            {carton.dimensions.weight}kg
          </li>

          <li>
            <span className="font-medium">Ilość sztuk pjm w kartonie: </span>
            {carton.baseUnitCount}
          </li>

          <li>
            <span className="font-medium">
              Dodatkowe informacje o produkcie (komunikaty produktowe):{" "}
            </span>
          </li>
          {remarks}
        </ul>
      </div>
    );
  } else {
    return (
      <div className="product-card__details px-8 py-8">
        <ul className="product-card__details--list">
          <li>
            <span className="font-medium">Ean: </span>
            {props.res.gtin}
          </li>

          <li>
            <span className="font-medium">Kraj pochodzenia: </span>
            {props.res.countryOfOrigin}
          </li>

          <li>
            <span className="font-medium">
              Wymiary pjm (wysokość/ szerokość/ długość):{" "}
            </span>
            {piece.dimensions.height} x {piece.dimensions.width} x{" "}
            {piece.dimensions.length}
          </li>

          <li>
            <span className="font-medium">Waga pjm: </span>
            {piece.dimensions.weight}kg
          </li>

          <li>
            <span className="font-medium">
              Wymiary kartonu (wysokość/ szerokość/ długość):{" "}
            </span>
            {carton.dimensions.height} x {carton.dimensions.width} x{" "}
            {carton.dimensions.length}
          </li>

          <li>
            <span className="font-medium">Waga kartonu: </span>
            {carton.dimensions.weight}kg
          </li>

          <li>
            <span className="font-medium">Ean kartonu: </span>
            {carton.gtin}
          </li>

          <li>
            <span className="font-medium">Ilość sztuk pjm w kartonie: </span>
            {carton.baseUnitCount}
          </li>

          <li>
            <span className="font-medium">
              Dodatkowe informacje o produkcie (komunikaty produktowe):{" "}
            </span>
          </li>
          {remarks}
        </ul>
      </div>
    );
  }
}

export default ProductDetails;
