import "./App.scss";
import AppHeader from "./components/appHeader.js";
import ProductHeader from "./components/productHeader.js";
import ProductDetails from "./components/productDetails.js";
import React, { useState, useEffect } from "react";

const App = (props) => {
  const [apiData, setApiData] = useState([]);
  const [hasLoaded, setHasLoaded] = useState();

  useEffect(() => {
    async function fetchData(ean) {
      try {
        const response = await fetch(
          window.location.origin + "/product/" + ean + ".json",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();

        //różne struktóry json-ów
        if (json.data) {
          setApiData(json.data[0]);
        } else {
          setApiData(json);
        }
        setHasLoaded(true);
      } catch (e) {
        console.error(e);
        setHasLoaded(false);
      }
    }
    fetchData(props.match.params.id);
  }, []);

  if (hasLoaded === true) {
    return (
      <div className="App">
        <AppHeader />
        <div className="container mx-auto product-card">
          <ProductHeader res={apiData} ean={props.match.params.id} />
          <ProductDetails res={apiData} ean={props.match.params.id} />
        </div>
      </div>
    );
  } else {
    return <div className="App">Load</div>;
  }
};

export default App;
